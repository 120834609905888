@import 'pages/variables.css';

.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  transform-origin: center;
  animation: spin 4s linear infinite;
}

.circle {
  fill: transparent;
  stroke-width: 6%;
  stroke-linecap: round;
  stroke-dasharray: 220%;
}

.gray .circle {
  stroke: rgba($colorDarkGray, 0.2);
}

.white .circle {
  stroke: rgb($colorWhite);
}

.blue .circle {
  stroke: rgb($colorBlue);
}

.red .circle {
  stroke: rgb($colorRed);
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(540deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}
