@import 'pages/variables.css';

$linkColor: $colorBlue;
$linkHoverColor: $colorHover;
$linkVisitedColor: 85, 26, 139;

.root {
  color: rgb($linkColor);
  transition: color 0.07s ease-out, border-color 0.1s ease-out;

  text-decoration: none;
  cursor: pointer;
}

.root:hover,
.root:visited:hover {
  color: rgb($linkHoverColor);
  border-color: rgba($linkVisitedColor, 0.3);
}

.root svg {
  fill: rgb($linkColor);
}

.root:hover svg {
  fill: rgb($linkHoverColor);
}

.underlined {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.underlined:hover {
  border-color: rgba($linkHoverColor, 0.3);
}

.black,
.black:visited {
  color: rgb($colorBlack);
  border-color: rgba($colorBlack, 0.3);
}

.red,
.red:hover,
.red:visited,
.red:visited:hover {
  color: rgb($colorRed);
  border-color: rgb($colorRed, 0.3);
}

.white,
.white:hover,
.white:visited,
.white:visited:hover {
  color: rgb($colorWhite);
  border-color: rgb($colorWhite, 0.3);
}

.white svg, .white svg path {
  fill: rgb($colorWhite);
}

.button,
.button:visited,
.button:visited:hover {
  font-size: 20px;
  line-height: 24px;
  white-space: nowrap;

  padding: 16px 24px;
  max-height: 56px;
  border-radius: 4px;

  background-color: rgb($colorBlue);
  color: rgb($colorWhite);

  transition: background-color $transition, color $transition, border-color $transition;
}

.root.button:hover {
  background-color: rgb($colorBlueHover);
}

.lightButton,
.lightButton:visited {
  border-radius: 4px;
  padding: 14px 16px;
  font-size: 16px;
  line-height: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-bottom: none;
  display: inline-flex;
  background-color: rgb($colorControl);
  color: rgb($colorBlue);
  margin-bottom: 24px;
}

.lightButton:hover,
.lightButton:visited:hover {
  color: rgb($colorBlue);
  background-color: rgb($colorSmoothBlueHover);
}

.lightButton:active,
.lightButton:visited:active {
  color: rgb($colorBlue);
  background-color: rgb($colorSmoothBlueActive);
}
