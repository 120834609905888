@import 'pages/variables.css';

.root {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  margin-right: 20px;
  
  &:hover svg path {
    fill: rgb($colorHover);
  }
}

.arrowIcon {
  width: 32px;
}

.image {
  height: 32px;
  width: 48px;
  margin-right: 8px;
  border-radius: 4px;
}

.root svg path {
  fill: rgb($colorBlue);
  transition: fill $transition;
}

@media (min-width: $breakpointSmall) {
  .root {
    margin-right: 40px;
  }
}
