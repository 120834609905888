@import 'pages/variables.css';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 4px;

  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  white-space: nowrap;

  transition: background-color $transition, color $transition, border-color $transition;
}

.root:hover {
  cursor: pointer;
}

.root.xs {
  font-size: 11px;
  line-height: 20px;
  padding: 2px 6px;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: 700;
}

.root.sm {
  font-size: 16px;
  line-height: 20px;
  padding: 8px 16px;
}

.root.text.sm,
.root.redtext.sm {
  font-size: 14px;
  line-height: 20px;
}

.root.md {
  padding: 10px 16px;
}

.root.lg {
  padding: 16px 24px;
  max-height: 56px;
}

.root.blue {
  background-color: rgb($colorBlue);
  color: rgb($colorWhite);

  &:hover {
    background-color: rgb($colorBlueHover);
  }

  &:active {
    background-color: rgb($colorBlueActive);
  }
}

.root.blue .icon svg path {
  fill: rgb($colorWhite);
}

.root.text,
.root.redtext,
.root.ghostWithArrow,
.root.textWithArrow {
  font-size: 16px;
  line-height: 20px;
  padding: 0;
  background-color: transparent;
  color: rgb($colorBlue);

  &:hover {
    color: rgb($colorHover);
  }
}

.root.redtext {
  color: rgb($colorRed);

  &:hover {
    color: rgb($colorBlue);
  }
}

.root.textWithArrow,
.root.ghostWithArrow {
  position: relative;
  padding: 12px 32px 12px 0;
  justify-content: flex-start;
  border-bottom: 1px solid rgb($colorGray);
}

.root.ghostWithArrow {
  border-bottom: none;
}

.root.textWithArrow::after,
.root.ghostWithArrow::after {
  content: '';
  position: absolute;
  right: 0;
  width: 32px;
  height: 32px;

  @svg-load arrow url('components/common/icons/right-arrow-thin.svg') {
    fill: rgb($colorBlue);
  }

  background: svg-inline(arrow) no-repeat center;
}

.root.textWithArrow:hover::after,
.root.ghostWithArrow:hover::after {
  @svg-load arrow url('components/common/icons/right-arrow-thin.svg') {
    fill: rgb($colorHover);
  }

  background: svg-inline(arrow) no-repeat center;
}

.root.ghostWithArrow {
  position: relative;
  padding: 12px 32px 12px 0;
  justify-content: flex-start;
}

.root.outline {
  border: 1px solid rgb($colorLightBlue);
  background-color: transparent;
  color: rgb($colorBlue);

  &:hover {
    color: rgb($colorHover);
    border-color: rgb($colorSmoothRed);
  }
}

.root.smoothBlue {
  background-color: rgb($colorSmoothBlue);
  color: rgb($colorBlue);

  &:hover {
    background-color: rgb($colorSmoothBlueHover);
  }

  &:active {
    background-color: rgb($colorSmoothBlueActive);
  }
}

.root.smoothRed {
  background-color: rgb($colorSmoothRed);
  color: rgb($colorRed);

  &:hover {
    background-color: rgb($colorSmoothRedHover);
  }

  &:active {
    background-color: rgb($colorSmoothRedActive);
  }
}

.root.smoothBlue.md,
.root.smoothBlue.lg,
.root.smoothRed.md,
.root.smoothRed.lg {
  font-size: 16px;
}

.root.block {
  width: 100%;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.spinner {
  margin-right: 8px;
}

.root[disabled],
.root[disabled]:hover {
  background-color: rgb($colorGray);
  color: rgb($colorWhite);
  cursor: not-allowed;
}

.root.text[disabled],
.root.redtext[disabled],
.root.textWithArrow[disabled],
.root.text[disabled]:hover,
.root.redtext[disabled]:hover,
.root.ghostWithArrow[disabled],
.root.ghostWithArrow[disabled]:hover,
.root.textWithArrow[disabled]:hover {
  background-color: transparent;
  color: rgb($colorGray);
  cursor: not-allowed;
}

@media (min-width: $breakpointMedium) {
  .root.textWithArrow {
    padding: 0 32px 0 0;
    border-bottom: none;
  }
}
