@import 'pages/variables.css';

.s36 {
  font-size: 36px;
  line-height: 130%;
}

.s28 {
  font-size: 28px;
  line-height: 114%;
}

.s24 {
  font-size: 24px;
  line-height: 133%;
}

.s20 {
  font-size: 20px;
  line-height: 120%;
}

.s18 {
  font-size: 18px;
  line-height: 133%;
}

.s16 {
  font-size: 16px;
  line-height: 150%;
}

.s14 {
  font-size: 14px;
  line-height: 143%;
}

.s12 {
  font-size: 12px;
  line-height: 133%;
}

.s10 {
  font-size: 10px;
  line-height: 160%;
}

.s9 {
  font-size: 9px;
  line-height: 168%;
}

.black {
  color: rgba($colorBlack, 0.87);
}

.darkGray {
  color: rgb($colorGray60);
}

.white {
  color: rgb($colorWhite);
}

.blue {
  color: rgb($colorBlue);
}

.green {
  color: rgb($colorGreen);
}

.orange {
  color: rgb($colorOrange);
}

.error {
  color: rgb($colorError);
}

.bold {
  font-weight: 700;
}

/* desktop */
@media all and (min-width: $breakpointSmall) {
  .d36 {
    font-size: 36px;
    line-height: 130%;
  }

  .d28 {
    font-size: 28px;
    line-height: 114%;
  }

  .d24 {
    font-size: 24px;
    line-height: 133%;
  }

  .d20 {
    font-size: 20px;
    line-height: 120%;
  }

  .d18 {
    font-size: 18px;
    line-height: 133%;
  }

  .d16 {
    font-size: 16px;
    line-height: 150%;
  }

  .d14 {
    font-size: 14px;
    line-height: 143%;
  }

  .d12 {
    font-size: 12px;
    line-height: 133%;
  }

  .d10 {
    font-size: 10px;
    line-height: 160%;
  }

  .d9 {
    font-size: 9px;
    line-height: 168%;
  }
}
