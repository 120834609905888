@import 'pages/variables.css';

.root {
  position: relative;
  display: flex;
  font-size: 0;
  width: 134px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: 114px 24px;
  background-position: 0 0;
}

.root::before {
  content: 'Бронирование жилья';
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: rgb($colorBlue);
}

.christmas::before {
  @svg-load christmas url('components/layout/header/logo/icons/christmas.svg') {
    path {
      fill: #0AB03E;
    }
  }
  content: '';
  position: absolute;
  right: 2px;
  top: 4px;
  width: 13px;
  height: 15px;
  background-image: svg-inline(christmas);
  background-size: contain;
}

.christmas::after {
  @svg-load star url('components/layout/header/logo/icons/star.svg') {
    path {
      fill: #F86600;
    }
  }
  content: '';
  position: absolute;
  right: 6px;
  top: -1px;
  width: 5px;
  height: 5px;
  background-image: svg-inline(star);
  background-size: contain;
}

.blue {
  @svg-load logo url('components/layout/header/logo/icons/logo.svg') {
    path {
      fill: rgb($colorBlue);
    }
  }

  background-image: svg-inline(logo);
}

.white {
  @svg-load logo url('components/layout/header/logo/icons/logo.svg') {
    path {
      fill: rgb($colorWhite);
    }
  }

  background-image: svg-inline(logo);
}

.white::before {
  color: rgb($colorWhite);
}

@media all and (min-width: $breakpointTiny) {
  .root {
    width: 120px;
    background-size: 100px 21px;
  }
}

@media all and (min-width: 375px) {
  .root {
    width: 134px;
    background-size: 114px 24px;
  }
}

@media (min-width: $breakpointSmall) {
  .root {
    width: 240px;
    height: 44px;
    background-size: 212px 44px;
  }

  .root::after {
    top: 10px;
    width: 19px;
    height: 24px;
  }

  .root::before {
    content: none;
  }
  .christmas::before {
    right: 0;
    top: 8px;
    width: 22px;
    height: 26px;
  }
  
  .christmas::after {
    right: 7px;
    top: 0;
    width: 8px;
    height: 8px;
  }
}
